.habit-curator-text {
  font-size: calc(60px + 1vw);
}

.gpt-question {
  font-size: calc(45px + 1vw);
}

.input-text::placeholder {
  color: gray;
}

.chat-container {
  padding-left: 200px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .chat-container {
    padding-left: 0px;
    margin-top: 90px;
  }
}
